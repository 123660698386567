import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: { inline: "", color: "secondary" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "heading",
                    fn: function () {
                      return [
                        _c(VIcon, { attrs: { large: "" } }, [
                          _vm._v("mdi-translate"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h5" }, [
                          _c("b", [_vm._v(_vm._s(_vm.$tc("translation", 2)))]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1155113783
              ),
            },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "17px",
                    "font-weight": "600",
                    "margin-top": "16px",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("translation_obs_header")) + " ")]
              ),
              _vm._l(3, function (t) {
                return _c("p", { key: t, staticClass: "mb-4" }, [
                  _c("b", [_vm._v(_vm._s(t + ". "))]),
                  _vm._v(_vm._s(_vm.$t("translation_obs_" + t)) + " "),
                ])
              }),
              _c(VDivider, { staticClass: "mb-7" }),
              _c(
                VForm,
                {
                  ref: "form",
                  attrs: { "lazy-validation": _vm.lazy },
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.get_translation_fields()
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "5" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              items: _vm.options,
                              "item-text": "Name",
                              rules: [_vm.required],
                              solo: "",
                              outlined: "",
                              hint: _vm.$t("translation_company_hint"),
                              "return-object": "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.get_briefing_options(
                                  _vm.optionSelected.Briefings
                                )
                              },
                            },
                            model: {
                              value: _vm.optionSelected,
                              callback: function ($$v) {
                                _vm.optionSelected = $$v
                              },
                              expression: "optionSelected",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "5" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              disabled:
                                !_vm.optionSelected.Briefings ||
                                _vm.optionSelected.Briefings.length < 2,
                              items: _vm.briefingOptions,
                              solo: "",
                              rules: [_vm.required],
                              outlined: "",
                              hint: _vm.$t("translation_briefing_hint"),
                              "item-text": "Text",
                              "return-object": "",
                            },
                            model: {
                              value: _vm.briefingSelected,
                              callback: function ($$v) {
                                _vm.briefingSelected = $$v
                              },
                              expression: "briefingSelected",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                color: "primary",
                                type: "submit",
                                height: "56",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "14px" } },
                                [_c("b", [_vm._v(_vm._s(_vm.$t("select")))])]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(VRow, [_c(VDivider)], 1),
                ],
                1
              ),
              (_vm.briefing || _vm.fields.length > 0) && !_vm.fieldsLoading
                ? _c(
                    VRow,
                    { staticClass: "mt-5", attrs: { align: "center" } },
                    [
                      _c("h1", { staticClass: "h5 ml-2" }, [
                        _c("b", [
                          _vm._v(" " + _vm._s(_vm.optionSelected.Name)),
                        ]),
                        _vm.briefing
                          ? _c("b", [
                              _vm._v(
                                " - " +
                                  _vm._s(
                                    _vm.briefing.IsBriefing
                                      ? _vm.$t("briefing")
                                      : _vm.$t("profile")
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "primary",
                            height: "30",
                            loading: _vm.saveLoading,
                          },
                          on: { click: _vm.translate_briefing },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "13px" } }, [
                            _c("b", [_vm._v(_vm._s(_vm.$t("save")))]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.briefing || _vm.fields.length > 0) && !_vm.fieldsLoading
                ? _c(VSimpleTable, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("field"))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("edit"))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.fields, function (f, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(f.label)),
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "secondary",
                                  attrs: { "min-width": "0", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit_field(f)
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v("mdi-pencil"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm.fieldsLoading
                ? _c(
                    VRow,
                    { staticClass: "my-6", attrs: { justify: "center" } },
                    [
                      _c(VProgressCircular, {
                        attrs: {
                          indeterminate: "",
                          size: "70",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm.editFieldDialog
            ? _c(
                VDialog,
                {
                  attrs: { "max-width": "650px" },
                  model: {
                    value: _vm.editFieldDialog,
                    callback: function ($$v) {
                      _vm.editFieldDialog = $$v
                    },
                    expression: "editFieldDialog",
                  },
                },
                [
                  _c("EditFieldDialog", {
                    attrs: { Field: _vm.fieldValue, FieldName: _vm.fieldName },
                    on: {
                      close: function ($event) {
                        _vm.editFieldDialog = false
                      },
                      save: _vm.save_field,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VRow,
        { attrs: { justify: "center" } },
        [
          _c(VProgressCircular, {
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }