import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "edit-field-dialog" } },
    [
      _c(
        VCard,
        { staticStyle: { "overflow-y": "auto", "max-height": "90vh" } },
        [
          _c(VCardTitle, { staticClass: "mb-6" }, [
            _c("h4", { staticClass: "dxa_modal_title h4" }, [
              _vm._v(_vm._s(_vm.FieldName)),
            ]),
          ]),
          _c(
            VCardText,
            [
              _c(
                VForm,
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.save()
                    },
                  },
                },
                [
                  _c(VTextarea, {
                    staticClass: "my-2",
                    attrs: {
                      readonly: "",
                      outlined: "",
                      label: _vm.keys
                        .find(function (x) {
                          return x == "pt"
                        })
                        .toUpperCase(),
                    },
                    model: {
                      value:
                        _vm.field[
                          _vm.keys.find(function (x) {
                            return x == "pt"
                          })
                        ],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.field,
                          _vm.keys.find(function (x) {
                            return x == "pt"
                          }),
                          $$v
                        )
                      },
                      expression: "field[keys.find((x) => x == `pt`)]",
                    },
                  }),
                  _vm._l(
                    _vm.keys.filter(function (x) {
                      return x != "pt"
                    }),
                    function (k, i) {
                      return _c(VTextarea, {
                        key: i,
                        staticClass: "my-2",
                        attrs: {
                          disabled: k == "pt",
                          outlined: "",
                          label: k.toUpperCase(),
                        },
                        model: {
                          value: _vm.field[k],
                          callback: function ($$v) {
                            _vm.$set(_vm.field, k, $$v)
                          },
                          expression: "field[k]",
                        },
                      })
                    }
                  ),
                  _c(
                    VForm,
                    {
                      ref: "newTranslationForm",
                      attrs: { "lazy-validation": _vm.newTranslationLazy },
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.add_translation()
                        },
                      },
                      model: {
                        value: _vm.newTranslationValid,
                        callback: function ($$v) {
                          _vm.newTranslationValid = $$v
                        },
                        expression: "newTranslationValid",
                      },
                    },
                    [
                      _c(
                        VRow,
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "4" } },
                            [
                              _c(VTextField, {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  outlined: "",
                                  rules: [
                                    _vm.two_characters_required,
                                    _vm.translation_exists,
                                  ],
                                },
                                model: {
                                  value: _vm.newTranslation,
                                  callback: function ($$v) {
                                    _vm.newTranslation = $$v
                                  },
                                  expression: "newTranslation",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "mb-7",
                                  attrs: { text: "", type: "submit" },
                                },
                                [
                                  _c(
                                    VRow,
                                    { attrs: { align: "center" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-transform": "none",
                                          },
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("add_new_translation")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "ml-2",
                                          attrs: { color: "primary" },
                                        },
                                        [_vm._v("mdi-plus")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    { staticClass: "pa-2 dxa_modal_actions" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnSuccess",
                          attrs: {
                            color: "primary",
                            "min-width": "100",
                            loading: _vm.loading,
                            type: "submit",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnError",
                          attrs: { color: "red", "min-width": "100" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close", null)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.error
                ? _c(
                    VAlert,
                    { staticClass: "ma-2", attrs: { type: "error" } },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.error)) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }